import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Login from './components/Login/Login';
import Draft from './components/LincolnGames/Draft';
import TeamsDashboard from './components/TeamsDashboard/TeamsDashboard';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Cookies from 'universal-cookie';

function App() {
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  if(!token) {
    const cookies = new Cookies();
    let cookie_token = cookies.get('token');
    if(cookie_token) {
      if(typeof cookie_token['token'] !== 'undefined') {
        setToken(cookie_token['token']);
      }
      if(typeof cookie_token['user'] !== 'undefined') {
        setUser(cookie_token['user']);
      }
    }
  }
  if(token == 'success') {
    return (
      <div className="App">
        <header className="App-header">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<TeamsDashboard logged_user={user} setToken={setToken} setUser={setUser} />} />
              <Route path="/lincoln-games/draft" element={<Draft logged_user={user} setToken={setToken} setUser={setUser} />} />
            </Routes>
          </BrowserRouter>
        </header>
      </div>
    );
  } else {
    return (
      <div className="App">
        <header className="App-header">
          <Login setToken={setToken} setUser={setUser} />
        </header>
      </div>
    )
  }
}

export default App;