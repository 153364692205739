import React, { useState, useEffect, useReducer } from 'react';
import './TeamsDashboard.css';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image'
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Cookies from 'universal-cookie';
import InputMask from 'react-input-mask';
import { Table } from 'react-bootstrap';

library.add(fas, fab);


function TeamsDashboard({ logged_user, setToken, setUser }) {
  const [teams, setTeams] = useState([]);
  const [rankingList, setRankingList] = useState([]);
  const [leaderboard, setLeaderboard] = useState(null);
  const [aos, setAOs] = useState([]);
  const [random_shuffle, setRandomShuffle] = useState([]);
  const [showNewTeamForm, setShowNewTeamForm] = useState(false);
  const [newTeamLogo, setNewTeamLogo] = useState('');
  const [newTeamName, setNewTeamName] = useState('');
  const [filterTeam, setFilterTeam] = useState('');
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [showPaxModal, setShowPaxModal] = useState(false);
  const [showRankingsModal, setShowRankingsModal] = useState(false);
  const [selectedRankingName, setSelectedRankingName] = useState('');
  const [selectedPax, setSelectedPax] = useState('');
  const [paxActivities, setPaxActivities] = useState('');
  const [activityForm, setActivityForm] = useState([]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const user = logged_user;
  useEffect(() => {
    (async () => {
        if(user) {
            let res_user = await getUser(user.id);
            setUser(res_user);
            const cookies = new Cookies();
            const cookie_token = cookies.get('token');
            cookie_token.user = res_user; 
        }
        await delay(500);
        setShowNewTeamForm(false);
        setShowActivityModal(false);
        let res = await getTeams();
        setTeams(res.teams);
        setLeaderboard(res.leaderboard);
        
        let res_rand = await getRandomShuffle();
        setRandomShuffle(res_rand);
        await delay(3000);
        let res_aos = await getAOs();
        setAOs(res_aos);
    })();
  }, []);
  function setFormField(field, value) {
    let form = activityForm;
    form[field] = value;
    setActivityForm(form);  
    forceUpdate();  
  }
  async function getPaxActivities(user_id) {
    return axios({
        url: '/pax-activities/' + user_id,
        method: 'GET'
    })
        .then(response => response.data)
  }
  async function getTeams() {
    return axios({
        url: '/teams',
        method: 'GET'
    })
        .then(response => response.data)
  }
  async function getAOs() {
    return axios({
        url: '/aos',
        method: 'GET'
    })
        .then(response => response.data)
  }
  async function getUser(user_id) {
    return axios({
        url: '/scrooged/pax?user_id=' + user_id,
        method: 'GET'
    })
        .then(response => response.data)
  }
  async function getRandomShuffle() {
    return axios({
        url: '/random-shuffle',
        method: 'GET'
    })
        .then(response => response.data)
  }
  async function joinTeam(team_id, user_id, team = null) {
    if(!user.team_id || team_id === -1) {
        if(team_id === -1) {
            if(!window.confirm('Leave your current team?')) {
                return false;
            }
        } else {
            if(!window.confirm('Join team?')) {
                return false;
            }
        }
        await axios({
            url: '/join-team',
            method: 'POST',
            data: {"team_id": team_id, "user_id": user_id}
        })
            .then(response => response.data)
        let res = await getTeams();
        setTeams(res.teams);
        setLeaderboard(res.leaderboard);
        user.team_id = team_id === -1 ? null : (team_id === 'random' ? null : team_id);
        const cookies = new Cookies();
        const cookie_token = cookies.get('token');
        cookie_token.user.team_id = user.team_id; 
        cookie_token.user.team = team;
        user.team = team;
        if(team_id === 'random') {
            cookie_token.user.random_team = 1;
            user.random_team = 1;
        }
        if(team_id === -1) {
            cookie_token.user.random_team = null;
            user.random_team = null;
        }
        setUser(user);
        cookies.set('token', JSON.stringify(cookie_token), { path: '/' });
    }
  }
  async function createNewTeam() {
    if(!user.team_id) {
        let response = await axios({
            url: '/create-team',
            method: 'POST',
            data: {"name": newTeamName, "logo": newTeamLogo, "user_id": user.id}
        });
        
        let res = await getTeams();
        setTeams(res.teams);
        setLeaderboard(res.leaderboard);
        user.team_id = response.data.id;
        const cookies = new Cookies();
        const cookie_token = cookies.get('token');
        cookie_token.user.team_id = user.team_id; 
        cookies.set('token', JSON.stringify(cookie_token), { path: '/' });
    }
  }
  const addActivity = async e => {
    e.preventDefault();
    let form = activityForm;
    let response = await axios({
        url: '/add-activity',
        method: 'POST',
        data: {
            "user_id": user.id,
            "type": form['type'], 
            "name": form['name'], 
            "fastest_mile": form['fastest_mile'],
            "miles": form['miles'],
            "total_time": form['total_time'],
            "merkins": form['merkins'],
            "pullups": form['pullups'],
            "burpees": form['burpees'],
            "ao_id": form['ao_id'],
            "created_at": form['created_at']
        }
    });
    setActivityForm([]);
    let res_user = await getUser(user.id);
    setUser(res_user);
    const cookies = new Cookies();
    const cookie_token = cookies.get('token');
    cookie_token.user = res_user; 
    let res = await getTeams();
    setTeams(res.teams);
    setLeaderboard(res.leaderboard);
    setShowActivityModal(false);  
  }
  function toggleTeam(team_id) {
    let new_teams = teams;
    for(var team in new_teams) {
        if(new_teams[team].id == team_id) {
            new_teams[team]['show_team'] = new_teams[team]['show_team'] ? 0 : 1;
            console.log(new_teams[team]['show_team']);
        }
    }
    setTeams(new_teams);
    forceUpdate();  
  } 
  async function showPax(user) {
    setSelectedPax(user);
    setPaxActivities([]);
    let res = await getPaxActivities(user.id);
    setPaxActivities(res);
    setShowPaxModal(true);
    forceUpdate();  
  } 
  async function deleteTeam(team_id) {
    await axios({
        url: '/delete-team',
        method: 'POST',
        data: {"team_id": team_id, "user_id": user.id}
    })
        .then(response => response.data)
    let res = await getTeams();
    setTeams(res.teams);
    setLeaderboard(res.leaderboard);
    user.team_id = null;
    const cookies = new Cookies();
    const cookie_token = cookies.get('token');
    cookie_token.user.team_id = user.team_id; 
    cookies.set('token', JSON.stringify(cookie_token), { path: '/' });
    
  }
  async function deleteActivity(activity_id) {
    if(!window.confirm('Delete Activity?')) {
        return false;
    }
    await axios({
        url: '/delete-activity',
        method: 'POST',
        data: {"activity_id": activity_id, "user_id": user.id}
    })
        .then(response => response.data)
    let res = await getPaxActivities(user.id);
    setPaxActivities(res);
    forceUpdate();  
  }
  async function resyncStrava(user) {
    await axios({
        url: '/backfill-activities/' + user.id,
        method: 'GET'
    })
    .then(response => response.data);
    let res = await getPaxActivities(user.id);
    setPaxActivities(res);
    forceUpdate();  
  }
  function logout() {
    const cookies = new Cookies();
    cookies.remove('token');
    setToken(null);
    setUser(null);
  }

  function showRankings(value, level, order) {
    let rankings = [];
    setSelectedRankingName(value.replaceAll('_', ' ').toUpperCase());
    setRankingList(rankings);
    if(level == 'pax') {
        let paxs = [];
        for(var team in teams) {
            paxs = [...paxs, ...teams[team].pax];
        }
        paxs.sort(function(a, b) {
            if(parseFloat(a[value]) == 0) return 1;
            if(parseFloat(b[value]) == 0) return -1;
            if (parseFloat(a[value]) < parseFloat(b[value])) return order == 'ASC' ? -1 : 1;
            if (parseFloat(a[value]) > parseFloat(b[value])) return order == 'ASC' ? 1 : -1;
            return 0;
        });
        for(var pax in paxs) {
            rankings.push({"name": paxs[pax].name, "value": paxs[pax][value]});
        }
    } else if(level == 'team') {
        let rank_teams = [...teams];
        rank_teams.sort(function(a, b) {
            if(parseFloat(a[value]) == 0) return 1;
            if(parseFloat(b[value]) == 0) return -1;
            if (parseFloat(a[value]) < parseFloat(b[value])) return order == 'ASC' ? -1 : 1;
            if (parseFloat(a[value]) > parseFloat(b[value])) return order == 'ASC' ? 1 : -1;
            return 0;
        });
        for(var rank_team in rank_teams) {
            rankings.push({"name": rank_teams[rank_team].name, "value": rank_teams[rank_team][value]});
        }
    }
    
    setRankingList(rankings);
    setShowRankingsModal(true);
  }
  return(
    <Container>
        <h1 className="title"><Image fluid rounded src="https://f3naperville.com/wp-content/uploads/2024/11/SCOOGED-general-winter-csaup-challenge-header-1024x341.png" /></h1>
        <h5><a target="_blank" href="https://f3naperville.com/scrooged-2024/">RULES</a></h5>
        
        <Card className="popout">
            <Card.Body> 
                <Row>
                    <Col xs="2">
                        <Image fluid rounded src={user.logo} />
                    </Col>
                    <Col>
                        <div className="pax-name">{user.name} <Button type="button" variant="outline-light" onClick={logout}><FontAwesomeIcon icon={["fas", "right-from-bracket"]}/> Logout</Button></div>
                        {!user.strava_athlete_id && <div className="pax-name"><a className="strava btn btn-outline-light" href={"https://www.strava.com/oauth/mobile/authorize?client_id=97264&response_type=code&approval_prompt=auto&scope=activity:read_all&redirect_uri=https://api.f3.dispatchq.com/scrooged/strava-connect/" + user.slack_id}><FontAwesomeIcon icon={["fab", "strava"]} /> CONNECT YOUR STRAVA</a></div>}
                        {user.strava_athlete_id && <div className="pax-name text-success font-weight-bold"><FontAwesomeIcon icon={["fab", "strava"]} /> STRAVA CONNECTED</div>}
                        {(user.team_id && user.team) &&  <div className="pax-name team-title">{user.team.name}</div>}
                        {(user.random_team) &&  <div className="pax-name team-title">RANDOM SHUFFLE</div>}
                        {(user.team_id || user.random_team == 1) && <div className="pax-name"><Button type="button" variant="danger" onClick={() => joinTeam(-1, user.id)}>Leave Team</Button></div>}
                        <div className="pax-stats">
                            <div>
                                <FontAwesomeIcon icon={["fas", "person-running"]} /> <span className="text-success font-weight-bold">{user.run_miles ?? 0}</span> miles in <span className="text-success font-weight-bold">{user.runs ?? 0}</span> runs&nbsp;|&nbsp;
                                <FontAwesomeIcon icon={["fas", "suitcase-rolling"]} /> <span className="text-danger font-weight-bold">{user.ruck_miles ?? 0}</span> miles in <span className="text-danger font-weight-bold">{user.rucks ?? 0}</span> rucks
                            </div>
                            <div>Fastest Mile: <span className="text-success font-weight-bold">{user.run_fastest_mile ? (new Date(user.run_fastest_mile * 1000).toISOString().substr(14, 5)) : 'N/A'}</span></div>
                            <div>Nutcracker Time: <span className="text-success font-weight-bold">{user.nutcracker_time ? (new Date(user.nutcracker_time * 1000).toISOString().substr(11, 8)) : 'N/A'}</span> <br/>  Frosty: {user.frosty_time > 0 && <FontAwesomeIcon className="text-success font-weight-bold" icon={["fas", "circle-check"]} /> } {!user.frosty_time && <FontAwesomeIcon className="text-danger font-weight-bold" icon={["fas", "ban"]} /> }</div>
                            <div>
                                Chest Presses: <span className="text-success font-weight-bold">{user.nutcracker_merkins ?? 'N/A'}</span><br/>  
                                Coupon Squats: <span className="text-success font-weight-bold">{user.nutcracker_pullups ?? 'N/A'}</span> <br/> 
                                Blockies: <span className="text-success font-weight-bold">{user.nutcracker_burpees ?? 'N/A'}</span></div>
                        </div>
                        <div>
                            <Button type="button" variant="success" onClick={() => setShowActivityModal(true)}><FontAwesomeIcon icon={["fas", "plus"]} /> Add Activity</Button>&nbsp;&nbsp;&nbsp;
                            <Button type="button" variant="outline-light"  onClick={() => showPax(user)}><FontAwesomeIcon icon={["fas", "list"]} /> Recorded Activities</Button>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        
        {leaderboard && <Card className="popout">
            <Card.Body>
                <h1 className="leaderboard">Dashboard</h1> 
                <Row className="pax-record">
                    <Col lg="6" xs="12">
                        <h1 className="leaderboard">Team</h1>
                        <Table bordered className="team-title leaders">
                            <tbody>
                                {leaderboard.top_team_run_miles.team != 'N/A' && <tr onClick={() => showRankings('team_run_miles', 'team', 'DESC')}><td>Most Run Miles:</td><td>{leaderboard.top_team_run_miles.team}</td><td><span className="text-success font-weight-bold">{leaderboard.top_team_run_miles.miles}</span> miles</td></tr>}
                                {leaderboard.top_team_ruck_miles.team != 'N/A' && <tr onClick={() => showRankings('team_ruck_miles', 'team', 'DESC')}><td>Most Ruck Miles:</td><td>{leaderboard.top_team_ruck_miles.team}</td><td><span className="text-danger font-weight-bold">{leaderboard.top_team_ruck_miles.miles}</span> miles</td></tr>}
                                {leaderboard.top_team_runs.team != 'N/A' && <tr onClick={() => showRankings('team_runs', 'team', 'DESC')}><td>Most Runs:</td><td>{leaderboard.top_team_runs.team}</td><td><span className="text-success font-weight-bold">{leaderboard.top_team_runs.count}</span></td></tr>}
                                {leaderboard.top_team_rucks.team != 'N/A' && <tr onClick={() => showRankings('team_rucks', 'team', 'DESC')}><td>Most Rucks:</td><td>{leaderboard.top_team_rucks.team}</td><td><span className="text-danger font-weight-bold">{leaderboard.top_team_rucks.count}</span></td></tr>}
                                {leaderboard.top_team_fastest_mile.team != 'N/A' && <tr onClick={() => showRankings('team_run_fastest_mile', 'team', 'ASC')}><td>Avg Fastest Mile:</td><td>{leaderboard.top_team_fastest_mile.team}</td><td><span className="text-success font-weight-bold">{(new Date(leaderboard.top_team_fastest_mile.pace * 1000).toISOString().substr(14, 5))}</span></td></tr>}
                                {leaderboard.top_team_nutcracker_time.team != 'N/A' && <tr onClick={() => showRankings('team_nutcracker_time', 'team', 'ASC')}><td>Best Nutcracker Time:</td><td>{leaderboard.top_team_nutcracker_time.team}</td><td><span className="text-success font-weight-bold">{(new Date(leaderboard.top_team_nutcracker_time.time * 1000).toISOString().substr(11, 8))}</span></td></tr>}
                                {leaderboard.top_team_nutcracker_merkins.team != 'N/A' && <tr onClick={() => showRankings('team_nutcracker_merkins', 'team', 'DESC')}><td>Most Chest Presses in 2m:</td><td>{leaderboard.top_team_nutcracker_merkins.team}</td><td><span className="text-success font-weight-bold">{leaderboard.top_team_nutcracker_merkins.count}</span></td></tr>}
                                {leaderboard.top_team_nutcracker_pullups.team != 'N/A' && <tr onClick={() => showRankings('team_nutcracker_pullups', 'team', 'DESC')}><td>Most Coupon Squats in 2m:</td><td>{leaderboard.top_team_nutcracker_pullups.team}</td><td><span className="text-success font-weight-bold">{leaderboard.top_team_nutcracker_pullups.count}</span></td></tr>}
                                {leaderboard.top_team_nutcracker_burpees.team != 'N/A' && <tr onClick={() => showRankings('team_nutcracker_burpees', 'team', 'DESC')}><td>Most Blockies in 2m:</td><td>{leaderboard.top_team_nutcracker_burpees.team}</td><td><span className="text-success font-weight-bold">{leaderboard.top_team_nutcracker_burpees.count}</span></td></tr>}
                            </tbody>
                        </Table>
                    </Col>
                    <Col lg="6" xs="12">
                        <h1 className="leaderboard">PAX</h1>
                        <Table bordered className="team-title leaders">
                            <tbody>
                                {leaderboard.top_pax_run_miles.pax != 'N/A' && <tr onClick={() => showRankings('run_miles', 'pax', 'DESC')}><td>Most Run Miles:</td><td>{leaderboard.top_pax_run_miles.pax}</td><td><span className="text-success font-weight-bold">{leaderboard.top_pax_run_miles.miles}</span> miles</td></tr>}
                                {leaderboard.top_pax_ruck_miles.pax != 'N/A' && <tr onClick={() => showRankings('ruck_miles', 'pax', 'DESC')}><td>Most Ruck Miles:</td><td>{leaderboard.top_pax_ruck_miles.pax}</td><td><span className="text-danger font-weight-bold">{leaderboard.top_pax_ruck_miles.miles}</span> miles</td></tr>}
                                {leaderboard.top_pax_runs.pax != 'N/A' && <tr onClick={() => showRankings('runs', 'pax', 'DESC')}><td>Most Runs:</td><td>{leaderboard.top_pax_runs.pax}</td><td><span className="text-success font-weight-bold">{leaderboard.top_pax_runs.count}</span></td></tr>}
                                {leaderboard.top_pax_rucks.pax != 'N/A' && <tr onClick={() => showRankings('rucks', 'pax', 'DESC')}><td>Most Rucks:</td><td>{leaderboard.top_pax_rucks.pax}</td><td><span className="text-danger font-weight-bold">{leaderboard.top_pax_rucks.count}</span></td></tr>}
                                {leaderboard.top_pax_fastest_mile.pax != 'N/A' && <tr onClick={() => showRankings('run_fastest_mile', 'pax', 'ASC')}><td>Fastest Mile:</td><td>{leaderboard.top_pax_fastest_mile.pax}</td><td><span className="text-success font-weight-bold">{(new Date(leaderboard.top_pax_fastest_mile.pace * 1000).toISOString().substr(14, 5))}</span></td></tr>}
                                {leaderboard.top_pax_nutcracker_time.pax != 'N/A' && <tr onClick={() => showRankings('nutcracker_time', 'pax', 'ASC')}><td>Best Nutcracker Time:</td><td>{leaderboard.top_pax_nutcracker_time.pax}</td><td><span className="text-success font-weight-bold">{(new Date(leaderboard.top_pax_nutcracker_time.time * 1000).toISOString().substr(11, 8))}</span></td></tr>}
                                {leaderboard.top_pax_nutcracker_merkins.pax != 'N/A' && <tr onClick={() => showRankings('nutcracker_merkins', 'pax', 'DESC')}><td>Most Chest Presses in 2m:</td><td>{leaderboard.top_pax_nutcracker_merkins.pax}</td><td><span className="text-success font-weight-bold">{leaderboard.top_pax_nutcracker_merkins.count}</span></td></tr>}
                                {leaderboard.top_pax_nutcracker_pullups.pax != 'N/A' && <tr onClick={() => showRankings('nutcracker_pullups', 'pax', 'DESC')}><td>Most Coupon Squats in 2m:</td><td>{leaderboard.top_pax_nutcracker_pullups.pax}</td><td><span className="text-success font-weight-bold">{leaderboard.top_pax_nutcracker_pullups.count}</span></td></tr>}
                                {leaderboard.top_pax_nutcracker_burpees.pax != 'N/A' && <tr onClick={() => showRankings('nutcracker_burpees', 'pax', 'DESC')}><td>Most Blockies in 2m:</td><td>{leaderboard.top_pax_nutcracker_burpees.pax}</td><td><span className="text-success font-weight-bold">{leaderboard.top_pax_nutcracker_burpees.count}</span></td></tr>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Card>}
        <Form.Group className="mb-3">
            <Form.Control type="text" placeholder="Filter Team by Name" onChange={e => setFilterTeam(e.target.value)}/>
        </Form.Group>
        <Card className="popout">
            <Card.Title className="team-title">RANDOM SHUFFLE PAX</Card.Title>
            <Card.Body> 

                <Stack direction="horizontal" gap="2">
                    {random_shuffle.map(pax => (
                        <div className="pax-name">{pax.name}</div>
                    ))}
                </Stack>

            </Card.Body>
        </Card>
        <Row>
            {!user.team_id && !user.random_team && <Col xs="12" lg="4">
                <Card className="popout team" onClick={() => joinTeam('random', user.id)}>
                    <Card.Body>
                        <div className="add-team-icon"><FontAwesomeIcon icon={["fas", "shuffle"]} /></div>
                        <Card.Title className="team-title">Join Random Shuffle</Card.Title>
                        <Card.Text>
                            Team up with a RANDOM set of PAX for maximum 2ndF value for the whole month of CSAUP!
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>}
            {!user.team_id && !user.random_team && <Col xs="12" lg="4">
                <Card className="popout team">
                    <Card.Body onClick={() => setShowNewTeamForm(true)}>
                        <div className="add-team-icon"><FontAwesomeIcon icon={["fas", "plus"]} /></div>
                        <Card.Title className="team-title">Create New Team</Card.Title>
                        {!showNewTeamForm && <Card.Text>
                            Fresh Start. Get other 5 PAX to join to take on this holiday CSAUP
                        </Card.Text>}
                        {showNewTeamForm && <Card.Text>
                            <Form>
                                <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Name" onChange={e => setNewTeamName(e.target.value)}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" placeholder="Logo URL" onChange={e => setNewTeamLogo(e.target.value)}/>
                                </Form.Group>
                                <Button variant="outline-light" type="button" onClick={createNewTeam}>Create New Team</Button>
                            </Form>
                        </Card.Text>}
                    </Card.Body>
                </Card>
                
            </Col>}
            {teams.map(team => (
                <>
                {(!filterTeam || team.name.toLowerCase().includes(filterTeam.toLowerCase())) && <Col xs="12" lg="4">
                    <Card className="popout team">
                        <Card.Img className="team-logo" variant="top" src={team.logo} />
                        <Card.Body>
                            <Card.Title className="team-title" onClick={() => toggleTeam(team.id)}>
                                <p>{team.name}{team.created_by === user.id && <FontAwesomeIcon className="team-delete" icon={["fas", "trash-can"]} onClick={() => deleteTeam(team.id)}/>}</p>
                                <div className="pax-stats mx-auto text-center">
                                    <p>
                                        <FontAwesomeIcon icon={["fas", "person-running"]} /> <span className="text-success font-weight-bold">{team.team_run_miles ?? 0}</span> miles in <span className="text-success font-weight-bold">{team.team_runs ?? 0}</span> runs&nbsp;<br/>&nbsp;
                                        <FontAwesomeIcon icon={["fas", "suitcase-rolling"]} /> <span className="text-danger font-weight-bold">{team.team_ruck_miles ?? 0}</span> miles in <span className="text-danger font-weight-bold">{team.team_rucks ?? 0}</span> rucks
                                    </p>
                                    <p>Avg Fastest Mile: <span className="text-success font-weight-bold">{team.team_run_fastest_mile ? (new Date(team.team_run_fastest_mile * 1000).toISOString().substr(14, 5)) : 'N/A'}</span></p>
                                    <p>Nutcracker Time: <span className="text-success font-weight-bold">{team.team_nutcracker_time ? (new Date(team.team_nutcracker_time * 1000).toISOString().substr(11, 8)) : 'N/A'}</span> <br/> Frosty: {team.team_frosty_time > 0 && <FontAwesomeIcon className="text-success font-weight-bold" icon={["fas", "circle-check"]} /> } {!team.team_frosty_time && <FontAwesomeIcon className="text-danger font-weight-bold" icon={["fas", "ban"]} /> }</p>
                                    <p>
                                        Chest Presses: <span className="text-success font-weight-bold">{team.team_nutcracker_merkins ?? 'N/A'}</span> <br/> 
                                        Coupon Squats: <span className="text-success font-weight-bold">{team.team_nutcracker_pullups ?? 'N/A'}</span> <br/> 
                                        Blockies: <span className="text-success font-weight-bold">{team.team_nutcracker_burpees ?? 'N/A'}</span></p>
                                    <p className="show-team"><FontAwesomeIcon icon={["fas", team.show_team ? "chevron-up" : "chevron-down"]} /> </p>
                                </div>
                            </Card.Title>
                            {team.show_team == 1 && <Card.Text className={team.show_team == 1 ? "show" : "hide"}>
                                {team.pax.map(pax => (
                                    <>
                                    <hr/>
                                    <Row className="pax-record" onClick={() => showPax(pax)}>
                                        <Col xs="3">
                                            <Image className="pax-logo" rounded fluid src={pax.logo} />
                                        </Col>
                                        <Col xs="9">
                                            <div className="pax-name">{pax.name} {pax.strava_athlete_id && <a target="_blank" className="strava btn-sm btn btn-outline-light" href={"https://www.strava.com/athletes/" + pax.strava_athlete_id}><FontAwesomeIcon icon={["fab", "strava"]} /></a>}</div>
                                            <div className="pax-stats mx-auto">
                                                <div>
                                                    <FontAwesomeIcon icon={["fas", "person-running"]} /> <span className="text-success font-weight-bold">{pax.run_miles ?? 0}</span> miles in <span className="text-success font-weight-bold">{pax.runs ?? 0}</span> runs&nbsp;<br/> &nbsp;
                                                    <FontAwesomeIcon icon={["fas", "suitcase-rolling"]} /> <span className="text-danger font-weight-bold">{pax.ruck_miles ?? 0}</span> miles in <span className="text-danger font-weight-bold">{pax.rucks ?? 0}</span> rucks
                                                </div>
                                                <div>Fastest Mile: <span className="text-success font-weight-bold">{pax.run_fastest_mile ? (new Date(pax.run_fastest_mile * 1000).toISOString().substr(14, 5)) : 'N/A'}</span></div>
                                                <div>Nutcracker Time: <span className="text-success font-weight-bold">{pax.nutcracker_time ? (new Date(pax.nutcracker_time * 1000).toISOString().substr(11, 8)) : 'N/A'}</span> <br/>  Frosty: {pax.frosty_time > 0 && <FontAwesomeIcon className="text-success font-weight-bold" icon={["fas", "circle-check"]} /> } {!pax.frosty_time && <FontAwesomeIcon className="text-danger font-weight-bold" icon={["fas", "ban"]} /> }</div>
                                                <div>
                                                    Chest Presses: <span className="text-success font-weight-bold">{pax.nutcracker_merkins != 0 ? pax.nutcracker_merkins : 'N/A'}</span> <br/>  
                                                    Coupon Squats: <span className="text-success font-weight-bold">{pax.nutcracker_pullups != 0 ? pax.nutcracker_pullups : 'N/A'}</span> <br/>  
                                                    Blockies: <span className="text-success font-weight-bold">{pax.nutcracker_burpees != 0 ? pax.nutcracker_burpees : 'N/A'}</span></div>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                    
                                    </>
                                ))}
                                
                                {!user.team_id && !user.random_team && [...Array(6 - Math.min(6, team.pax.length))].map((x, i) =>
                                    <Row className="pax-record" onClick={() => joinTeam(team.id, user.id, team)}>
                                        
                                            <Col xs="3">
                                                <FontAwesomeIcon className="pax-logo" icon={["fas", "square-plus"]} />
                                            </Col>
                                            <Col xs="9">
                                                <div className="pax-name">JOIN THIS TEAM</div>
                                            </Col>                                        
                                    </Row>
                                )}
                                {(user.team_id || user.random_team) && [...Array(6 - Math.min(6, team.pax.length))].map((x, i) =>
                                    <Row className="pax-record">  
                                        <Col xs="3">
                                            <FontAwesomeIcon className="pax-logo" icon={["fas", "circle-user"]} />
                                        </Col>
                                        <Col xs="9">
                                            <div className="pax-name">EMPTY SPOT</div>
                                        </Col>                                        
                                    </Row>
                                )}
                            
                            </Card.Text>}
                        </Card.Body>
                    </Card>
                </Col>}
                </>
            ))}
            
            
        </Row>
        <Modal show={showActivityModal}>
            <Modal.Header>
                <Modal.Title>Add Activity</Modal.Title>
                <FontAwesomeIcon className="activity-modal-close-btn" icon={["fas", "close"]} onClick={() => setShowActivityModal(false)}/>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={addActivity}>
                    <Form.Group className="mb-3">
                        <Form.Select aria-label="Activity Type" value={activityForm['type']} required onChange={e => setFormField('type', e.target.value)}>
                            <option value="">--SELECT ACTIVITY TYPE--</option>
                            <option value="run">RUN The Ghost of Christmas Present - The Rudolph</option>
                            <option value="ruck">RUCK The Ghost of Christmas Present - The Rudolph</option>
                            <option value="frosty">Ghost of Christmas Past - The Frosty</option>
                            <option value="nutcracker">The Ghost of Christmas Future - The Nutcracker</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Select aria-label="AO" value={activityForm['ao_id']} required onChange={e => setFormField('ao_id', e.target.value)}>
                            <option value="">--SELECT AO--</option>
                            {aos.map(ao => (
                                <option value={ao.id}>{ao.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control type="text" required placeholder="Activity Name" value={activityForm['name']} onChange={e => setFormField('name', e.target.value)}/>
                    </Form.Group>                
                    {activityForm['type'] == 'run' && <Form.Group className="mb-3">
                        <InputMask className="form-control" required placeholder="Fastest Mile (mm:ss)" value={activityForm['fastest_mile']} mask="99:99" maskChar={null}  onChange={e => setFormField('fastest_mile', e.target.value)} />
                    </Form.Group>}
                    <Form.Group className="mb-3">
                        <Form.Control type="number" required placeholder="Miles" value={activityForm['miles']} onChange={e => setFormField('miles', e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <InputMask className="form-control" required placeholder="Total Time (hh:mm:ss)" value={activityForm['total_time']} mask="99:99:99" maskChar={null}  onChange={e => setFormField('total_time', e.target.value)} />
                    </Form.Group>
                    {activityForm['type'] == 'nutcracker' && <Form.Group className="mb-3">
                        <Form.Control type="number" step="1" required placeholder="Chest Presses" value={activityForm['merkins']} onChange={e => setFormField('merkins', e.target.value)}/>
                    </Form.Group>}
                    {activityForm['type'] == 'nutcracker' && <Form.Group className="mb-3">
                        <Form.Control type="number" step="1" required placeholder="Coupon Squats" value={activityForm['pullups']} onChange={e => setFormField('pullups', e.target.value)}/>
                    </Form.Group>}
                    {activityForm['type'] == 'nutcracker' && <Form.Group className="mb-3">
                        <Form.Control type="number" step="1" required placeholder="Blockies" value={activityForm['burpees']} onChange={e => setFormField('burpees', e.target.value)}/>
                    </Form.Group>}
                    <Form.Group className="mb-3">
                        <Form.Control type="datetime-local" required placeholder="Date and Time" value={activityForm['created_at']} onChange={e => setFormField('created_at', e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Button variant="success" type="submit">Add Activity</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>

        <Modal size="xl" show={showPaxModal}>
            <Modal.Header>
                <Modal.Title><Image className="pax-modal-logo" fluid rounded src={selectedPax.logo} /> {selectedPax.name} Activities</Modal.Title>
                <FontAwesomeIcon className="activity-modal-close-btn" icon={["fas", "close"]} onClick={() => setShowPaxModal(false)}/>
            </Modal.Header>

            <Modal.Body className="overflow-x-scroll">
                {(user.id == 101 || (selectedPax.id == user.id && user.strava_athlete_id)) && <Form.Group className="mb-3">
                    <Button type="button" variant="outline-light"  onClick={() => resyncStrava(selectedPax)}><FontAwesomeIcon icon={["fas", "refresh"]} /> <FontAwesomeIcon icon={["fab", "strava"]} /> ReSync Strava</Button>
                </Form.Group>}
                {paxActivities && <Table bordered>
                    <thead>
                        <tr>
                            <th>DATE</th>
                            <th>AO</th>
                            <th>NAME</th>
                            <th>TYPE</th>
                            <th>FASTEST MILE</th>
                            <th>MILES</th>
                            <th>TOTAL TIME</th>
                            {selectedPax.id == user.id && <th><FontAwesomeIcon icon={["fas", "trash-can"]}/></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {paxActivities.map(activity => (
                            <tr>
                                <td>{activity.created_at}</td>
                                <td>{activity.ao}</td>
                                <td>
                                    {!activity.activity_id && activity.name}
                                    {activity.activity_id && <span>{activity.name}&nbsp;&nbsp;&nbsp;<a target="_blank" className="strava btn-sm btn btn-outline-light" href={"https://www.strava.com/activities/" + activity.activity_id}><FontAwesomeIcon icon={["fab", "strava"]} /></a></span>}
                                </td>
                                <td>{activity.type}</td>
                                <td>{activity.fastest_mile > 0 ? (new Date(activity.fastest_mile * 1000).toISOString().substr(14, 5)) : 'N/A'}</td>
                                <td>{activity.miles}</td>
                                <td>{activity.total_time > 0 ? (new Date(activity.total_time * 1000).toISOString().substr(11, 8)) : 'N/A'}</td>
                                {activity.user_id == user.id && <td>{activity.deletable == 1 && <FontAwesomeIcon icon={["fas", "trash-can"]} onClick={() => deleteActivity(activity.id)}/>}</td>}
                            </tr>
                        ))}
                    </tbody>
                </Table>}
            </Modal.Body>
        </Modal>
        <Modal size="xl" show={showRankingsModal}>
            <Modal.Header>
                <Modal.Title className="team-title">{selectedRankingName} Rankings</Modal.Title>
                <FontAwesomeIcon className="activity-modal-close-btn" icon={["fas", "close"]} onClick={() => setShowRankingsModal(false)}/>
            </Modal.Header>

            <Modal.Body className="overflow-x-scroll">

                {rankingList && <Table bordered>
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th>{selectedRankingName}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rankingList.map(ranking => (
                            <tr>
                                <td className="team-title">{ranking.name}</td>
                                <td className="text-success font-weight-bold team-title">
                                    {selectedRankingName == 'TEAM NUTCRACKER TIME' || selectedRankingName == 'NUTCRACKER TIME' ? (new Date(ranking.value * 1000).toISOString().substr(11, 8)) : (
                                        selectedRankingName == 'TEAM RUN FASTEST MILE' || selectedRankingName == 'RUN FASTEST MILE' ? (new Date(ranking.value * 1000).toISOString().substr(14, 5)) :
                                        ranking.value)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>}
            </Modal.Body>
        </Modal>
    </Container>
    
  );
}
TeamsDashboard.propTypes = {
    logged_user: PropTypes.object
}
export default TeamsDashboard;