import React, { useState, useEffect, useReducer } from 'react';
import './LincolnGames.css';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image'
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Cookies from 'universal-cookie';
import InputMask from 'react-input-mask';
import { Table } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Select, { StylesConfig } from 'react-select';
import Pusher from 'pusher-js';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
  } from 'react-bs-datatable';


library.add(fas, fab);


function Draft({ logged_user, setToken, setUser }) {
  const [admins, setAdmins] = useState(['Snowden', 'Kapusta', 'Tinactin', 'Animal House', 'Parks n Rec']);
  const [draftOrder, setDraftOrder] = useState([]);
  const [selectedPax, setSelectedPax] = useState(null);
  const [showPaxStatsModal, setShowPaxStatsModal] = useState(false);
  const [currentDraft, setCurrentDraft] = useState({
    round: 1,
    pick_num: 1
  });
  const [draftPick, setDraftPick] = useState(null);
  const [paxs, setPaxs] = useState([]);

  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const user = logged_user;

  const avatar = (logo = 'transparent') => ({
        alignItems: 'center',
        display: 'flex',
        zIndex:1,
        ':before': {
            backgroundImage: "url('" + logo + "')",
            borderRadius: 10,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 50,
            width: 50,
        },
        ':hover': {
            backgroundColor: '#555'
        }
  });
        
  const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'black' }),
        menu: (styles) => ({ ...styles, backgroundColor: 'black' }),
        option: (styles,  { data }) => {
            return {
            ...styles,
            backgroundColor: 'black',
            ...avatar(data.logo)
            };
        },
        input: (styles) => ({ ...styles, color: 'white', backgroundColor: 'black'}),
        placeholder: (styles) => ({ ...styles, color: 'white', backgroundColor: 'black'}),
        singleValue: (styles, { data }) => ({ ...styles, color: 'white', backgroundColor: 'black' , ...avatar(data.logo) }),
  };
  useEffect(() => {
    (async () => {
        if(user) {
            let res_user = await getUser(user.slack_id);
            setUser(res_user);
            const cookies = new Cookies();
            const cookie_token = cookies.get('token');
            cookie_token.user = res_user; 
        }
        
        await delay(500);
        let res = await getDraftOrder();
        setDraftOrder(res);
        for(let pax in res) {
            if(!res[pax]['selected_pax']) {
                setCurrentDraft({"round":res[pax]['round'],"pick_num":res[pax]['pick_num']});
                break;

            }
        }
        await delay(1500);
        res = await getPaxs();
        setPaxs(res);
        const pusher = new Pusher('ac93bdea01e767a95900', {
            cluster: 'us2',
            encrypted: true
        });
        const channel = pusher.subscribe('draft');
        channel.bind('pick', data => {
            setDraftPick(data);
        });
        
    })();
  }, []);
  useEffect(() => {
    if (draftPick) handleDrafted(draftPick);
  }, [draftPick]);
  const filterPax = (inputValue) => {
    return paxs.filter((i) =>
        inputValue ? i.label.toLowerCase().includes(inputValue.toLowerCase()) : true
    );
  };
  
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {

        resolve(filterPax(inputValue));
  
    });
  async function getDraftOrder() {
    return axios({
        url: '/lincoln-games/draft-order',
        method: 'GET'
    })
        .then(response => response.data)
  }
  async function getPaxs() {
    return axios({
        url: '/lincoln-games/paxs',
        method: 'GET'
    })
        .then(response => response.data)
  }
  
  async function getUser(user_id) {
    return axios({
        url: '/lincoln-games/pax?user_id=' + user_id,
        method: 'GET'
    })
        .then(response => response.data)
  }
   
  function handlePaxSelect(pax) {
    setSelectedPax(pax);
  }
  function handleDrafted(data) {
    let new_paxs = paxs;
    for(let pax in new_paxs) {
        if(new_paxs[pax]['slack_user_id'] == data['pick']['selected_pax']['slack_user_id']) {
            new_paxs.splice(pax, 1);
        }
    }
    setPaxs(new_paxs);
    let new_draft_order = draftOrder;
    for(let draft in new_draft_order) {
        if(new_draft_order[draft]['pick_num'] == data['pick']['pick_num']) {
            new_draft_order[draft]['selected_pax'] = data['pick']['selected_pax'];
        }
    }
    setDraftOrder(new_draft_order);
    setCurrentDraft(data['current_draft']);
    setSelectedPax(null);
    const audio = new Audio('/audio/goodresult.mp3');
    audio.play();
    document.getElementById('pick-' + data['current_draft']['pick_num']).focus();
    forceUpdate();
  }
  function lockInDraft() {
    return axios({
        url: '/lincoln-games/draft',
        method: 'POST',
        data: {"pick": currentDraft.pick_num, "pax_id": selectedPax.slack_user_id}
    })
        .then(response => response.data);
  }
  function showPaxStats() {
    setShowPaxStatsModal(true);
  }
  function logout() {
    const cookies = new Cookies();
    cookies.remove('token');
    setToken(null);
    setUser(null);
  }

  
  return(
    <div>
    <Navbar bg="dark" variant="dark" fixed="top">
        <Container>
         <img alt="" src={process.env.PUBLIC_URL + '/img/lg_logo_shield.png'} height="50" className="d-inline-block align-top" />
         <h1 className="leaderboard">LINCOLN GAMES 2024 DRAFT</h1>
         <div>
            <a target="_blank" href="https://f3naperville.com/lg-2024-rules/">RULES</a>&nbsp;&nbsp;
            <Button type="button" variant="danger" onClick={() => showPaxStats()}><FontAwesomeIcon icon={["fas", "list"]} /> PAX STATS</Button>
         </div>
        </Container>
    </Navbar>
    <Container className="draft">
        <Row>
            {draftOrder.map(draft => (
                <Col key={draft.pick_num} xs="12" lg="4">
                    <Card id={"pick-" + draft.pick_num} tabIndex={-1} className={draft.round == currentDraft.round && draft.pick_num == currentDraft.pick_num ? "team popout current" : "team popout"}>
                        <Card.Body>
                            <Card.Title className="team-title">
                                <div className="round">Round {draft.round}</div>
                                <div className="pick">Pick {draft.pick_num}</div>
                            </Card.Title>
                            <div className="card-text">
                                {draft.pick_num != currentDraft.pick_num && !draft.selected_pax && <div>
                                    <Card.Img className="team-logo" variant="top" src={draft.captain.team.logo} />
                                </div>}
                                {draft.round == currentDraft.round && draft.pick_num == currentDraft.pick_num && <div>
                                    <Card.Img className="team-logo" variant="top" src={draft.captain.team.logo} />
                                    <div className="cap-name">{draft.captain.team.name}</div>
                                    <h5 className="team-title">PICK DESIRED PAX</h5>
                                    {(draft.captain.slack_user_id == user.slack_user_id || admins.includes(user.name))&& <div>
                                        <AsyncSelect loadOptions={promiseOptions} defaultOptions cacheOptions styles={colourStyles}  onChange={handlePaxSelect}/>
                                    </div>}
                                    {selectedPax && <div>
                                        <Row className="pax-record">
                                            <Col xs="3">
                                                <Image className="pax-logo" rounded fluid src={selectedPax.logo} />
                                            </Col>
                                            <Col xs="9">
                                                <div className="pax-name">{selectedPax.slack_user_name} {selectedPax.strava_athlete_id && <a target="_blank" className="strava btn-sm btn btn-outline-light" href={"https://www.strava.com/athletes/" + selectedPax.strava_athlete_id}><FontAwesomeIcon icon={["fab", "strava"]} /></a>}</div>
                                                <div className="pax-stats mx-auto">
                                                    <div>Qs: <span className="text-success font-weight-bold">{selectedPax.q_num}</span></div>
                                                    <div>Posts: <span className="text-success font-weight-bold">{selectedPax.post_num}</span></div>
                                                    <div>Commitment: <span className="text-success font-weight-bold">{selectedPax.commitment_level}</span></div>
                                                    <div>Last Year Score: <span className="text-success font-weight-bold">{selectedPax.last_year_score}</span></div>
                                                </div>
                                                
                                            </Col>
                                        </Row>
                                        <Button type="button" variant="success" className="btn-lg" onClick={() => lockInDraft()}><FontAwesomeIcon icon={["fas", "plus"]} /> DRAFT {selectedPax.slack_user_name}</Button>

                                    </div>}
                                    {( draft.captain.slack_user_id != user.slack_user_id && !admins.includes(user.name)) && <div>
                                        {draft.captain.team.name} is DRAFTING...
                                    </div>}
                                    
                                </div>}
                                {draft.selected_pax && <div>
                                    <Card.Img className="team-logo" variant="top" src={draft.captain.team.logo} />
                                    <Row className="pax-record">
                                        <Col xs="3">
                                            <Image className="pax-logo" rounded fluid src={draft.selected_pax.slack_user_icon} />
                                        </Col>
                                        <Col xs="9">
                                            <div className="pax-name">{draft.selected_pax.slack_user_name}</div>
                                            <div className="pax-stats mx-auto">
                                                <div>Qs: <span className="text-success font-weight-bold">{draft.selected_pax.stats.q_num}</span></div>
                                                <div>Posts: <span className="text-success font-weight-bold">{draft.selected_pax.stats.post_num}</span></div>
                                                <div>Commitment: <span className="text-success font-weight-bold">{draft.selected_pax.stats.commitment_level}</span></div>
                                                <div>Last Year Score: <span className="text-success font-weight-bold">{draft.selected_pax.stats.last_year_score}</span></div>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </div>}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    </Container>
    <Modal size="xl" show={showPaxStatsModal}>
        <Modal.Header>
            <Modal.Title className="team-title">PAX Stats</Modal.Title>
            <FontAwesomeIcon className="activity-modal-close-btn" icon={["fas", "close"]} onClick={() => setShowPaxStatsModal(false)}/>
        </Modal.Header>

        <Modal.Body className="overflow-x-scroll">
            <DatatableWrapper 
                body={paxs} 
                headers={[
                    { title: 'PAX', prop: 'slack_user_name', isFilterable: true, isSortable: true},
                    { title: 'Posts', prop: 'post_num', isSortable: true },
                    { title: 'Qs', prop: 'q_num', isSortable: true },
                    { title: 'Commitment Level', prop: 'commitment_level', isFilterable: true, isSortable: true},
                    { title: 'Last Year Score', prop: 'last_year_score', isSortable: true }
                ]}
                paginationOptionsProps={{
                    initialState: {
                      rowsPerPage: 10,
                      options: [10, 20, 50, 100]
                    }
                }}
            >
                <Row className="mb-4 p-2">
                    <Col
                    xs={12}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                    >
                    <Filter />
                    </Col>
                    <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                    >
                    <PaginationOptions />
                    </Col>
                    <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                    >
                    <Pagination />
                    </Col>
                </Row>
                <Table>
                    <TableHeader />
                    <TableBody />
                </Table>
             </DatatableWrapper>

        </Modal.Body>
    </Modal>
    </div>
  );
}
Draft.propTypes = {
    logged_user: PropTypes.object
}
export default Draft;