import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import axios from "axios";
import Cookies from 'universal-cookie';

axios.defaults.baseURL = 'https://api.f3.dispatchq.com';
//axios.defaults.baseURL = 'http://localhost:5000';
async function loginUser(credentials) {
    return axios({
        url: '/login',
        method: 'POST',
        data: credentials
    })
        .then(response => response.data)
}
var token_response = null;
function Login({ setToken, setUser }) {
  const [username, setUserName] = useState();
  const [email, setEmail] = useState();
  
  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      email
    });
    token_response = token.token;
    setToken(token.token);
    setUser(token.user);
    const cookies = new Cookies();
    cookies.set('token', JSON.stringify(token), { path: '/', maxAge: 2147483647 });
  }
  return(
    <div>
        <img src={process.env.PUBLIC_URL + '/napervilleF3.jpg'} className="App-logo" alt="logo" />
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="F3 Name (Slack Handle)" onChange={e => setUserName(e.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Control type="email" placeholder="Email" onChange={e => setEmail(e.target.value)}/>
            </Form.Group>
            <Button variant="outline-light" type="submit">Login</Button>
            <br/><br/>
            {token_response == 'error' && <Alert  variant="danger">PAX Not Found!</Alert>}
        </Form>
    </div>
  )
}
Login.propTypes = {
    setToken: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired
}
export default Login;